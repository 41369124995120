import { useState } from 'react';
import { Container, Typography, TextField, Button, Snackbar, Alert } from "@mui/material";
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebaseSetup';
import { useAuth } from '../services/use-auth';


function ReportABug(props){
    const auth = useAuth();
    const [state, setState] = useState({ bug: ""});
    const [showMessage, setShowMessage] = useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value
        setState({ ...state, [name]: value });
    };
    const clearFields = () => {
        setState({ bug: "" })
    }
    const handleSend = async() => {
        const response =  await addDoc(collection(db, `bugs`), {description: state.bug, user: auth.user.id});
        if(response) {
            setShowMessage(true)
            clearFields();
        }
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowMessage(false);
    };
    return <Container>

<Typography variant='h5'>Report a bug</Typography>
        <Container sx={{margin:"50px 0"}}>
        <Typography variant="subtitle1"></Typography>
        <Container>
            <TextField multiline  minRows={3} name="bug" label="Bug description" variant="outlined" required fullWidth value={state.bug} onChange={handleChange} margin="normal"/>
          
           <Button disabled={!state.bug} variant="contained" 
           onClick={handleSend} margin="normal" sx={{margin: "10px 0"}}>
            Send bug report </Button>
        </Container> </Container>
        <Snackbar open={showMessage} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal:'center' }}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                       Your message has been received. Thank you!
                    </Alert>
                </Snackbar>
    </Container>
}

export default ReportABug;