import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import { green, orange, red } from '@mui/material/colors';

export default function InsetList(props) {
  return (
    <List
      sx={{ width: '100%', maxWidth: 360, height: 300, maxHeight:300, bgcolor: 'background.paper' }}
      aria-label="contacts"
      //itemCount={props.items.length}
    >
      {props.items.map((item) => {
         const color = item.status === 'ok' ? green[500] 
         : item.status==="warning" ? orange[500] : red[500];
         return <ListItem disablePadding key={item.key}>
          <ListItemButton>
            <ListItemIcon>
              <CircleIcon sx={{ color: color }} />
            </ListItemIcon>
            <ListItemText primary={item.serviceName} secondary={item.regionName} />
          </ListItemButton>
        </ListItem>
        }

      )}

    </List>
  );
}