// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { connectAuthEmulator } from "firebase/auth";
import { connectFirestoreEmulator } from "firebase/firestore";
import ReactObserver from 'react-event-observer';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAdgxyD5JhfqE4Q1gqtILVJWTZpsdwzQNM",
    authDomain: "bytegods.firebaseapp.com",
    projectId: "bytegods",
    storageBucket: "bytegods.appspot.com",
    messagingSenderId: "769469854272",
    appId: "1:769469854272:web:142ab74b3901f8644e65a5",
    measurementId: "G-LHG001YBEC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);


// connectAuthEmulator(auth, 'http://localhost:9099');
// connectFirestoreEmulator(db, 'localhost', 8080);

export { app, auth, db };