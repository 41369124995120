import { useState } from 'react';
import { Button, TextField, Container, Typography } from "@mui/material"
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebaseSetup';
import { useAuth } from '../services/use-auth';
import { Snackbar, Alert } from '@mui/material';

function Recommendations(props) {
    const auth = useAuth();
    const [state, setState] = useState({ name: "", link: "" });
    const [showMessage, setShowMessage] = useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value
        setState({ ...state, [name]: value });
    };
    const clearFields = () => {
        setState({ name: "", link: "" })
    }
    const handleSend = async() => {
        const response =  await addDoc(collection(db, `suggestions`), {name: state.name, link: state.link, type: "provider", user: auth.user.id});
        if(response) {
            setShowMessage(true)
            clearFields();
        }
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowMessage(false);
    };

    return <Container>
        <Typography variant='h5'>Suggest a provider</Typography>
        <Container sx={{margin:"50px 0"}}>
        <Typography variant="subtitle1">Interested in monitoring the status of a provider that we don't currently support? Let us know about them.</Typography>
        <Container>
            <TextField name="name" label="Provider name" variant="outlined" required fullWidth value={state.name} onChange={handleChange} margin="normal"/>
            <TextField name="link" label="Provider link" variant="outlined" required fullWidth value={state.link} onChange={handleChange} margin="normal" />
            
           <Button disabled={!state.name || !state.link} variant="contained" onClick={handleSend} margin="normal" sx={{margin: "10px 0"}}>Send recommendation</Button>
        </Container> </Container>
        <Snackbar open={showMessage} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal:'center' }}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                       Your message has been received. Thank you!
                    </Alert>
                </Snackbar>
    </Container>
}

export default Recommendations