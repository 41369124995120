import './App.css';
import Login from './components/Login'
import { Routes, Route, Navigate, useNavigate,} from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Subscriptions from './components/Subscriptions';
import Page from './components/shared/Page';
import Reset from './components/Login/Reset';
import { useAuth } from "./components/services/use-auth";
import { CircularProgress } from '@mui/material';
import Notifications from './components/Notifications';
import Recommendations from './components/Recommendations';
import Fab from '@mui/material/Fab';
import BugReportIcon from '@mui/icons-material/BugReport';
import ReportABug from './components/ReportABug';

const ProtectedRoute = ({ path, children }) => {
  const auth = useAuth();
  if(auth.isLoading) {
    return <CircularProgress></CircularProgress>
  }
  if (!auth.user) {
    return <Navigate to="/login"></Navigate>
  }

  return <Page path={path}>{children}</Page>;
};

const fabStyle = {
  margin: 0,
  top: 'auto',
  right: 20,
  bottom: 40,
  left: 'auto',
  position: 'fixed',
};

function App() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <Routes>
          <Route path='/login' exact element={<Login/>}></Route>
          <Route path='/' exact element={<ProtectedRoute path="/"><Dashboard/></ProtectedRoute>}></Route>
          <Route path='/subscriptions' exact element={<ProtectedRoute path="/subscriptions"><Subscriptions></Subscriptions></ProtectedRoute>}></Route>
          <Route path='/reset' element={<Reset/>}></Route>
          <Route path='/notifications' exact element={<ProtectedRoute path="/notifications"><Notifications/></ProtectedRoute>}></Route>
          <Route path='/recommendations' exact element={<ProtectedRoute path="/recommendations"><Recommendations/></ProtectedRoute>}></Route>
          <Route path='/reportabug' exact element={<ProtectedRoute><ReportABug/> </ProtectedRoute>} ></Route>
      </Routes>
      <Fab variant="extended" style={fabStyle} onClick={()=> navigate('/reportabug')}>
        <BugReportIcon sx={{ mr: 1 }} />
        Report a bug
      </Fab>
    </div>
  );
}

export default App;
