import { useState } from 'react';
import { Button, TextField, Container, Typography } from "@mui/material"
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebaseSetup';
import { useAuth } from '../services/use-auth';
import { Snackbar, Alert } from '@mui/material';

function NotificationsRecommendations(props) {
    const auth = useAuth();
    const [state, setState] = useState({ name: ""});
    const [showMessage, setShowMessage] = useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value
        setState({ ...state, [name]: value });
    };
    const clearFields = () => {
        setState({ name: ""})
    }
    const handleSend = async () => {
        const response = await addDoc(collection(db, `suggestions`), { name: state.name, type: "notifications", user: auth.user.id });
        if (response) {
            setShowMessage(true)
            clearFields();
        }
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowMessage(false);
    };

    return <Container sx={{margin: "50px auto"}}>
        <Typography variant="body2">Interested in being notified through other channel that we don't currently support? Let us know about them.</Typography>
        <Container>
            <TextField name="name" label="Notification channel name" variant="outlined" required fullWidth value={state.name} onChange={handleChange} margin="normal" />
            <Button disabled={!state.name} variant="contained" onClick={handleSend} margin="normal" sx={{ margin: "10px 0" }}>Send recommendation</Button>
        </Container>
        <Snackbar open={showMessage} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Your message has been received. Thank you!
            </Alert>
        </Snackbar>
    </Container>
}

export default NotificationsRecommendations