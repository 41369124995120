import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../services/use-auth';
import { useNavigate } from 'react-router-dom';




function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://bytegods.com/">
                Bytegods.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function SignIn() {
    const auth = useAuth();
    const [isSignUp, setIsSignUp] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const userInfo ={ email: data.get('email'), password: data.get('password') }
        try {
            const userData =  isSignUp ? await auth.signup(userInfo) : await auth.login(userInfo);
            navigate('/');
        } catch (e) {
            console.log(e.message)
        }
    };
    const handleGoToSignUp = () => {
        setIsSignUp(true);
    }

    const handleGoToSignIn = () => {
        setIsSignUp(false);
    }

    const handleForgotPassword = () => {
        navigate('/reset');
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {isSignUp ? "Sign up" : "Sign In"}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete={isSignUp ? "" : "current-password"}
                        />

                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {isSignUp ? "Sign up" : "Sign In"}
                        </Button>
                        <Grid container>
                            <Grid item xs>

                                {isSignUp ?
                                    (<Link href="#" variant="body2" onClick={handleGoToSignIn}>
                                        Already have an account? Sign in instead!
                                    </Link>)
                                    :
                                    (<Link href="#" variant="body2" onClick={handleForgotPassword}>
                                        Forgot password?
                                    </Link>)}
                            </Grid>
                            {!isSignUp && 
                            <Grid item xs>
                          
                                <Link href="#" onClick={handleGoToSignUp} variant="body2">
                                    {"Sign Up"}
                                </Link>
                            </Grid>}
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}