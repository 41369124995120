import { useState, useEffect } from 'react';
import { Container, TextField } from "@mui/material";
import HorizontalCard from "../shared/HorizontalCard.jsx";
import { collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebaseSetup';
import { useAuth } from '../services/use-auth.jsx';

function Dashboard() {
    const auth = useAuth();
    const [filterText, setFilterText] = useState("");

    const handleFilter = (ev) => {
        const text = ev.target.value;
        setFilterText(text);

    }

    const [itemsFromStore, setItemsFromStore] = useState([])
    useEffect(() => {
        const q = query(collection(db, `users/${auth.user.id}/subscriptions`));
        const unsub = onSnapshot(q, (qSnap) => {
            const data = [];
            qSnap.forEach((itemRef) => {
                const item = itemRef.data();
                const providerIndex = data.findIndex((prov) => prov.provider === item.provider)
                if (providerIndex > -1) {
                    data[providerIndex] = {
                        ...data[providerIndex],
                        count: data[providerIndex].count + 1,
                        okCount: data[providerIndex].okCount + (item.status === "ok" ? 1 : 0),
                        warningCount: data[providerIndex].warningCount + (item.status === "warning" ? 1 : 0),
                        errorCount: data[providerIndex].errorCount + (item.status === "error" ? 1 : 0),
                        services: [...data[providerIndex].services, item]
                    }
                } else {
                    data.push({
                        provider: item.provider,
                        providerName: item.providerName,
                        count: 1,
                        okCount: item.status === "ok" ? 1 : 0,
                        warningCount: item.status=== "warning" ? 1 : 0,
                        errorCount: item.status=== "error" ? 1 : 0,
                        services: [item]//{serviceKey: item.key, region:item.region, regionName: item.regionName, service: item.service, serviceName: item.serviceName, status: item.status}]
                    })
                }

            }
            );
            setItemsFromStore(data)

        })
        return () => { unsub(); }
    }, [])

    const items = itemsFromStore.filter((elem) => elem.providerName.toLowerCase().includes(filterText.toLowerCase()))
    return <Container >
        <Container maxWidth="sm" sx={{ padding: "20px" }}>
            <TextField id="filter" value={filterText} name="filter" onChange={handleFilter} fullWidth label="Filter" variant="outlined" />
        </Container>
        <Container sx={{ display: "flex", gap: 2, flexWrap: 'wrap' }}>{items.map((item) =>
            <HorizontalCard {...item}  key={item.provider}></HorizontalCard>
        )}
        </Container>

    </Container>
}

export default Dashboard;