import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import ResponsiveAppBar from "../shared/ResponsiveAppBar";

function Page({path, children}) {
    return <>
        <CssBaseline />

        <ResponsiveAppBar active={path}></ResponsiveAppBar>
        <Container maxWidth="lg" sx={{ margin: "30px auto" }}>
            {children}
        </Container></>
}

export default Page;