import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Chip from '@mui/material/Chip/Chip';
import Collapse from '@mui/material/Collapse/Collapse';
import InsetList from './InsetList';



const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


export default function HorizontalCard(props) {
    const theme = useTheme();
    const [expanded, setExpanded] = React.useState(false);
    
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const cardColor = props.okCount === props.count 
    ? "success"
    : (props.errorCount > 0 ?  "error" : "warning")

    return (
        <Card  onClick={handleExpandClick} sx={{ display: 'flex', maxWidth: 330, flexDirection:"column", padding: "10px", margin: "5px 0px"}}>
            <Box sx={{ display: 'flex', maxWidth: 370, alignItems: "center" }}>
            <Box

                sx={{ width: 151, height: 100, flex: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}
            >

                <Chip icon={props.okCount===props.count? <CheckIcon /> : <ErrorOutlineIcon/>} label={`${props.okCount}/${props.count}`} color={cardColor} />

            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 8 }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h6">
                        {props.providerName}
                    </Typography>
                    {/* <Typography variant="subtitle2" color="text.secondary" component="div">
                        {`Last update: ${props.date}`}
                    </Typography> */}
                </CardContent>
            </Box>
            <Box

                sx={{ width: 151, height: 100, flex: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}
            >
                <ExpandMore
                    expand={expanded}
                    
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </Box>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit sx={{maxHeight:400, overflow: "auto"}}>
                <CardContent >
                <InsetList items={props.services} ></InsetList>
                </CardContent>
            </Collapse>
        </Card>
    );
}