import { useState, useEffect } from 'react';
import { Typography, Container, TextField, List, ListItem, IconButton, Avatar,ListItemText,ListItemButton,  ListItemAvatar, Checkbox } from '@mui/material';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import { collection, deleteDoc, getDocs, where, onSnapshot,query, doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebaseSetup';
import { useAuth } from '../services/use-auth';
import SimpleList from '../shared/SimpleList';

function Services(props) {
    const auth = useAuth();
    const [itemsFromStore, setItemsFromStore] = useState([])//useState([{ id: 1, name: "Live From Space and another title", status: 0, date: "22-04-2024", isSubscribed: false }, { id:2, name: "when you care it shows :)", status: 0, date: "22-04-2024", isSubscribed: false }, { id: 3, name: "Live From Space and another title", status: 0, date: "22-04-2024", isSubscribed: false }, { id: 4,name: "when you care it shows :)", status: 0, date: "22-04-2024", isSubscribed: false }])
    const [subs, setSubs] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [selectedServices, setSelectedServices] = useState(itemsFromStore.filter((item)=> item.isSubscribed))
    props.callback(selectedServices);
    const handleFilter = (ev) => {
        const text = ev.target.value;
        setFilterText(text);

    }
    const getData = async() => {
        const querySnapshot = await getDocs(collection(db, `providers/${props.provider.id}/services`));
        const data = []
        querySnapshot.forEach((docRef)=> {
            data.push(docRef.data());
        })
        
        setItemsFromStore(data);
    }
    useEffect(()=>{
        getData();
    },[])

    useEffect(() => {
        const q = query(collection(db, `users/${auth.user.id}/subscriptions`), where("provider","==", props.provider.id), where("region", "==", props.zone.id));
        const unsub = onSnapshot(q, (qSnap) => {
            const data = [];
            qSnap.forEach((itemRef) => {
                const item = itemRef.data();
                data.push(item)
            }
            );
            setSubs(data)

        })
        return () => { unsub(); }
    }, [])
    const items = itemsFromStore.filter(elem => elem.name.toLowerCase().includes(filterText.toLowerCase()))

    const handleItemClick = (item) => {

        const itemIndex = subs.findIndex((sub)=> sub.service === item.id)

        if(itemIndex > -1) { //DELETE
            deleteDoc(doc(db, `users/${auth.user.id}/subscriptions/${props.provider.id}|${props.zone.id}|${item.id}`))

        } else { //ADD
           
            setDoc(doc(db, `users/${auth.user.id}/subscriptions/${props.provider.id}|${props.zone.id}|${item.id}`), {
                key: `${props.provider.id}|${props.zone.id}|${item.id}`,
                provider: props.provider.id,
                providerName: props.provider.name,
                region: props.zone.id,
                regionName: props.zone.name,
                service: item.id,
                serviceName: item.name,
                status: item.status
            })
        }
    }

    return <Container>
        <Typography variant="subtitle1" sx={{margin: "30px 0 0 0"}}> Select Services for {props.provider.name} {props.zone ? ` and ${props.zone.name}`: ""}</Typography>
        <Container maxWidth="sm" sx={{ padding: "20px" }}>
            <TextField id="filter" value={filterText} name="filter" onChange={handleFilter}
                fullWidth label="Filter" variant="outlined" />
        </Container>

        <Container maxWidth="sm">
            {/* <SimpleList items={items} handleItemClick={handleItemClick}></SimpleList> */}
        <List dense  sx={{ width: '100%', height: 400, maxHeight:400, bgcolor: 'background.paper', overflow: "auto", margin: "0px 0px 40px 0px" }}>
            {items.map((item) =>{
                return (<ListItem
                    onClick={() => handleItemClick(item)}
                    key={item.id}
                    secondaryAction={
                        <Checkbox
                          edge="end"
                          onChange={() => handleItemClick(item)}
                          checked={(subs.findIndex((sub)=>item.id ===sub.service)>-1)}
                          //inputProps={{ 'aria-labelledby': labelId }}
                        />
                      }
                      disablePadding
                >
                    <ListItemButton>
                    <ListItemAvatar>
                        <Avatar>
                            <CloudQueueIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={item.name}
                        //secondary={"secondary" ? 'Secondary text' : null}
                    />
                    </ListItemButton>
                </ListItem>)}
            )}
        </List>
        </Container>

    </Container>
}

export default Services;