import { useState } from 'react';
import { Typography, Container, TextField, List, ListItem, ListItemButton, Avatar,ListItemText, ListItemAvatar } from '@mui/material';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import { useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseSetup';
import SimpleList from '../shared/SimpleList';


function Providers(props) {

    const [filterText, setFilterText] = useState("");

    const handleFilter = (ev) => {
        const text = ev.target.value;
        setFilterText(text);

    }
    const [itemsFromStore, setItemsFromStore] = useState([{ id: 1, name: "Live From Space and another title", status: 0, date: "22-04-2024", isSubscribed: false }, { id:2, name: "when you care it shows :)", status: 0, date: "22-04-2024", isSubscribed: false }, { id: 3, name: "Live From Space and another title", status: 0, date: "22-04-2024", isSubscribed: false }, { id: 4,name: "when you care it shows :)", status: 0, date: "22-04-2024", isSubscribed: false }])
    

    const getData = async() => {
        const querySnapshot = await getDocs(collection(db, 'providers'));
        const data = []
        querySnapshot.forEach((docRef)=> {
            data.push(docRef.data());
        })
        setItemsFromStore(data);
    }
    useEffect(()=>{
        getData();
    },[])
    const items = itemsFromStore.filter(elem => elem.name.toLowerCase().includes(filterText.toLowerCase()))

    const handleItemClick = (item) => {
        props.callback(item);
    }


    return <Container>
         <Typography variant="subtitle1"  sx={{margin: "30px 0 0 0"}}> Select a provider</Typography>
    <Container maxWidth="sm" sx={{ padding: "20px" }}>
        <TextField id="filter" value={filterText} name="filter" onChange={handleFilter}
            fullWidth label="Filter" variant="outlined" />
    </Container>

    <Container maxWidth="sm">
        <SimpleList items={items} handleItemClick={handleItemClick}></SimpleList>
    {/* <List dense={"dense"} sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {items.map((item) =>
            <ListItem>
                 <ListItemButton onClick={() => handleItemClick(item)}>
                <ListItemAvatar>
                    <Avatar>
                        <CloudQueueIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={item.name}
                    secondary={item.history?.lastUpdated}
                />
                </ListItemButton>
            </ListItem>
        )}
    </List> */}
    </Container>

</Container>
}

export default Providers;