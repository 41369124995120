import { useState } from 'react';
import { Container, Stepper, Step, StepLabel, Button, ListItem } from '@mui/material';
import Services from './Services';
import Zones from './Zones';
import Providers from './Providers';
import { blue } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

function Subscriptions(props) {
    const navigate  = useNavigate();
    const [provider, setProvider] = useState(null);
    const [zone, setZone] = useState(null);
    const [step, setStep] = useState(0);

    const steps = [
        { label: "Providers", component: Providers, callback: (item) => {setProvider(item); handleNext()} },
        { label: "Zones", component: Zones, callback: (item) => {setZone(item); handleNext()} },
        { label: "Services", component: Services, callback: ()=>{} }]

    
    let canNext = step < steps.length - 1;
    let canBack = step > 0;
    let isLast = step === steps.length - 1;


    const handleNext = () => {
        if (canNext) {
            setStep(step + 1);
        }
    }
    const handleBack = () => {
        if (canBack) {
            if (step == 2) {
                //setServices([])
                setZone(null)
            }
            if(step==1) {
                setZone(null)
                setProvider(null)
            }
            setStep(step - 1);
        }
    }
    const handleSave = async () => {           
            clearStepper()
    }
    const clearStepper = () => {
        setStep(0);
        setProvider(null);
        setZone(null)
        //setServices([]);
    }
    let Component = steps[step].component;

    return <>
    
    <Container>
        <Stepper activeStep={step} alternativeLabel>
            {steps.map((s) => (
                <Step key={s.label}>
                    <StepLabel>{s.label}</StepLabel>
                </Step>
            ))}
        </Stepper>
        <Container>

            <Component callback={steps[step].callback} provider={provider} zone={zone}></Component>

            <Container>
                {/* {canNext && <Button variant="contained" onClick={handleNext}>Next</Button>} */}
                {canBack && <Button variant="text" onClick={handleBack}>Back</Button>}
               {isLast && <Button variant="contained" onClick={handleSave}>Done</Button>}
            </Container>
        </Container>
        <Container sx={{display:"flex", justifyContent: "space-around", margin:"30px 0m", padding:" 60px 0"}}>
            <Button variant="outlined" onClick={()=>{navigate("/notifications")}}>Configure notifications</Button>
            <Button variant="outlined" onClick={()=>{navigate("/recommendations")}}>Recommed a provider</Button>
        </Container>
    </Container>
    </>
}

export default Subscriptions;