import React, { useState, useEffect, useContext, createContext } from "react";
import { onAuthStateChanged, createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth, db } from '../../firebaseSetup';
import { onSnapshot, doc } from 'firebase/firestore';

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const login = async (data) => {
    const userData = await signInWithEmailAndPassword(auth, data.email, data.password);

    const result = {
      name: userData.user.displayName,
      id: userData.user.uid,
      email: userData.user.email
    };
    setUser(result);
    return result;
  };
  const signup = async (data) => {
    const userData = await createUserWithEmailAndPassword(auth, data.email, data.password);

    const result = {
      name: userData.user.displayName,
      id: userData.user.uid,
      email: userData.user.email,
      emailVerified: userData.user.emailVerified,
    };
    setUser(result);
    return result;
  };

  const logout = async (data) => {
    const userData = await signOut(auth);
    setUser(null)
    //dispatch(clean());
  };

  const reset = async (data) => {
    await sendPasswordResetEmail(auth, data.email);

    return true;
  };

  const getProfile = async () => {
    const uprofile = await signOut(auth);
    setUser(null)
    //dispatch(clean());
  };



  //   const confirmPasswordReset = async(code, password) => {
  //     await confirmPasswordReset(code, password)

  //     return true;
  //   };
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    setIsLoading(true)
    const unsubscribe = onAuthStateChanged(auth, (userData) => {
      if (userData) {
        setUser({
          name: userData.displayName,
          id: userData.uid,
          email: userData.email
        });
      } else {
        setUser(null);
      }

      setIsLoading(false)
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      const q = doc(db, `users/${user.id}`);
      const unsub = onSnapshot(q, (qSnap) => {
        setProfile(qSnap.data());
      })
      return () => { unsub(); }
    } else {
      setProfile({})
    }
  }, [user])

  // Return the user object and auth methods
  return {
    user,
    login,
    signup,
    logout,
    reset,
    profile,
    isLoading
    //confirmPasswordReset,
  };
}