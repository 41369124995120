import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import { green, red } from '@mui/material/colors';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import { Avatar, ListItemAvatar } from '@mui/material';
export default function SimpleList(props) {

    //TODO: get some sx info from props for more flexibility; also icon
  return (
    <List dense sx={{ width: '100%', height: 400, maxHeight:400, bgcolor: 'background.paper', overflow: "auto", margin: "0px 0px 20px 0px" }}>
        {props.items.map((item) =>{
        return  <ListItem key={item.id}>
                 <ListItemButton onClick={() => props.handleItemClick(item)}>
                <ListItemAvatar>
                    <Avatar>
                        <CloudQueueIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={item.name}
                    //secondary={"secondary" ? 'Secondary text' : null}
                />
                </ListItemButton>
            </ListItem>}
        )}
    </List>
  );
}