import { useState, useEffect } from 'react';
import { Container, Typography, FormControl, FormLabel, FormGroup, FormControlLabel, Switch } from "@mui/material"
import { useAuth } from '../services/use-auth';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebaseSetup';
import NotificationsRecommendations from '../Recommendations/NotificationChannel';

function Notifications(props) {
  const auth = useAuth();
  const settings = auth.profile?.settings
  const [state, setState] = useState({
    email: !!settings?.notifications.email
  });

  useEffect(() => {
    setState({
      email: !!settings?.notifications.email
    })
  }, [settings]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    updateDoc(doc(db, `users/${auth.user.id}`), { [`settings.notifications.${name}`]: value })
  };


  return <Container maxWidth="sm">

    <Typography variant="h5" sx={{ margin: "50px 0 50px" }}>Configure notifications</Typography>
    {/* <Typography variant="subtitle1" sx={{margin: "30px 0 0 0"}}> Notify me  when there is an incident by:</Typography> */}
    <Container maxWidth="sm" sx={{ margin: "30px 0" }} >
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">Notify me  when there is an incident by:</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={state.email} onChange={handleChange} name="email" />
            }
            label="Email"
          />
        </FormGroup>
      </FormControl>
    <NotificationsRecommendations ></NotificationsRecommendations>
    </Container>
  </Container>
}

export default Notifications